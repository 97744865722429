<template>
    <div class="course">
        <!-- <Top></Top> -->
        <div class="search_result">
            <Card></Card>
        </div>
    </div>
</template>
<script>
import Top from '../Index/components/Top.vue'
import Card from './components/Card.vue'
export default {
    
    name:'Course',
    components:{
        Top,
        Card
    },
    data(){
        return{

        }
    }
};
</script>
<style lang="less" scoped>
.course{
    width: 100%;
    height: 100%;
    background-color: #f7f7f7;

}
.search_result{
    width: 1200px;
    height: auto;
    margin: auto;
}
</style>