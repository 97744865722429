<template>
  <div class="cards">
    <div :style="{ paddingBottom: '20px' }"></div>
    <div class="card">
      <div class="list_item" v-for="(item, index) in menu_data" :key="index">
        <div class="list_item_label">{{ item.classify1 }}</div>
        <div class="list_item_valueBox">
          <div
            class="list_item_value"
            v-for="(item1, index1) in item.children"
            :key="index1"
            :class="item1.id == courseId ? 'active' : ''"
            @click="goCourse(item1.id)"
          >
            {{ item1.classify2 }}
          </div>
        </div>
      </div>
    </div>
    <div class="search_list">
      <div class="search_list_nav">
        <div class="search_list_nav_item nav_item_actived">全部课程</div>
      </div>
      <div class="search_list_content">
        <el-row :gutter="20">
          <el-col :span="6" v-for="(item, index) in course_data" :key="index">
            <el-card :body-style="{ padding: '10px' }" shadow="hover">
              <img :src="getImageUrl(item.img_url)" class="image" />
              <div style="padding: 14px">
                <span>{{ item.course }}</span>
                <div class="bottom clearfix">
                  <time class="time">￥{{ item.price }}</time>
                  <el-button
                    type="text"
                    class="button"
                    @click="study(item.course)"
                    >开始学习</el-button
                  >
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import {localStorageUtil} from "../../../LocalStorageUtil"
import config from '../../../config'

export default {
  name: "Card",
  data() {
    return {
      api: config.domain + "/",
      menu_data: [
        
      ],
      course_data: [
        
      ],
      courseId: 23,
    };
  },
  async created() {
    this.$route.query.id
      ? (this.courseId = this.$route.query.id)
      : (this.courseId );
    // console.log("Course ID:", this.courseId);
    // 其他逻辑
    let res=await this.$axios('get_courses')
      console.log(res.result.menu_data[0].children[0].id)
      // this.courseId=res.result.menu_data[0].children[0].id
      this.menu_data=res.result.menu_data
      this.menu_data.forEach(item=>{
        item.children.forEach(item1=>{
          if(item1.id==this.courseId){
            this.course_data=item1.children
          }
        })
      })
  },
  methods: {
    getImageUrl(imagePath) {
      const api = this.api;
      return `${api}${imagePath}`;
    }, 
    async goCourse(id) {
      // console.log(id,11111111111)
      this.courseId = id;
      if (this.$route.query.id != this.courseId) {
        this.$router.replace({
          name: "Courses",
          query: { id: id },
        });
      }
      // window.location.reload();
      // let res=await this.$axios('get_courses')
      // console.log(res.result.menu_data[0].children[0].id)
      // this.courseId=res.result.menu_data[0].children[0].id
      // this.menu_data=res.result.menu_data
      this.menu_data.forEach(item=>{
        item.children.forEach(item1=>{
          if(item1.id==this.courseId){
            this.course_data=item1.children
            console.log(this.course_data)
          }
        })
      })

    },
    study(classname) {
      const token = localStorageUtil.readExpire("token");
      if(token){
        this.$router.push({ name: "Classroom",
      query:{
    	classname:classname
    } });
      }else{
        this.$message('请先登录')
      }
    },
  },
};
</script>
<style lang="less" scoped>
.card {
  width: 100%;
  height: 30vh;
  overflow: scroll;
  background-color: #fff;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow-x: hidden;
  overflow-y: auto;
  .list_item {
    height: auto;
    padding: 0 0 0 20px;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    .list_item_label {
      width: 115px;
      height: 45px;
      line-height: 24px;
      text-align: left;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 700;
      color: #1a1a1a;
    }
    .list_item_valueBox {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      min-height: 45px;
      height: auto;
      line-height: 45px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      border-bottom: 1px solid #e5e5e5;
      .list_item_value {
        width: auto;
        height: 26px;
        line-height: 26px;
        margin: 10px 0;
        padding: 0 12px;
        text-align: left;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #6e6e6e;
        cursor: pointer;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
      }
      .active {
        background: #fcf4f3;
        border-radius: 13px;
        color: #f27979;
        font-weight: 700;
      }
    }
  }
}
.search_list {
  width: 100%;
  height: auto;
  background: #fff;
  border-radius: 10px;
  margin-top: 20px;
  .search_list_nav {
    width: 100%;
    height: 70px;
    line-height: 70px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px solid #f5f5f5;
    .nav_item_actived {
      color: #000;
      position: relative;
      padding: 0 20px;
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 700;
      color: #6e6e6e;
      cursor: pointer;
    }
    .nav_item_actived::after {
      content: " ";
      width: 24px;
      height: 4px;
      background: #ea5f59;
      border-radius: 2px;
      position: absolute;
      bottom: 13px;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
  .search_list_content {
    width: 100%;
    height: auto;
    padding-bottom: 20px;
    .time {
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 700;
      color: #ea5f59;
    }

    .bottom {
      margin-top: 13px;
      line-height: 12px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding-top: 20px;
      border-top: 1px solid #f5f5f5;
    }

    .button {
      padding: 0;
      float: right;
      color: #ea5f59;
    }

    .image {
      width: 100%;
      height: 100px;
      display: block;
    }

    .clearfix:before,
    .clearfix:after {
      display: table;
      content: "";
    }

    .clearfix:after {
      clear: both;
    }
    .el-col-6 {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}
</style>